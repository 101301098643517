.hwfooter-container {
    position: relative;
    max-height: 500px;
    overflow: hidden;
    background-color: #ACE7F2;
    padding-top: 4vh;
}

.hwfooter-container h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 55px;
    /* or 153% */
}

.hwfooter-container .waitlist-button {
    margin-bottom: 4vh;
}

.hwfooter-container p {
    width: 60%;
    margin: auto;
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 45px;
    text-align: center;
}

.hilltop {
    margin: auto;
    top: 95px;
}
