.home-container {
    /* width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between; */
    /* align-items: center; */
}

.home-container .headline {
    width: 100%;
    margin: auto;
}

.motto {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 45px;
    text-align: center;
    color: #000000;
}

.home-p {
    width: 100%;
    margin: auto;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    text-align: center;
    color: #000000;
}

.block-bg-container {
    display: flex;
    flex-direction: row;
}

.blue-block-bg {
    flex: 1;
    height: 376.97px;
    background: #B5E9F4;
}

.babyblue-block-bg {
    flex: 1;
    height: 376.97px;
    background: #D3E9F3;
}

.buttons {
    display: flex;
    margin: auto;
    width: 25%;
}

.home-container .buttons {
    display: flex;
    justify-content: center;
}

.helptc-solver h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 55px;
    text-align: center;
}

.home-solver {
    margin-top: -300px;
}

.home-solver h1 {
    font-size: 40px;
}

.home-solver-sub-headline {
    font-size: 25px;
    font-weight: 350;
}

.homeblock-sketchborder {
    margin: auto;
    background-image: url('../assets/sketch-border1.png');
    /* border: 3px solid blue; */
    background-position:39%;
    background-size:93% 100%;
    background-repeat:no-repeat;
    /* border: 3px solid blue; */
    /* background-image: url('../assets/sketch-border1.png'); */
    position: relative;
        top: -300px;
}

.line {
    border: 2px solid #F2EDE9;
}
.trees-container{
    display:flex;
    z-index: -1;
}

.video-container {
    width: 100%;
    height: 100%;
    position: relative;
    /* top: -490px; */
    margin-bottom: -10px;

}

.video-container video {
  width: 100%;
  max-width: 1900px;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.cover-content{
    margin: auto;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    position: absolute;
        top:450px;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
    /* background-color:rgba(255, 255, 255, 0.63);  */
    padding: 10px;
    border-radius: 25px;
}


/* @media only screen and (max-width: 850px) {
    * {
      width: 100%;
    }

    .video-container {
        width: 1500px;
        position: relative;
        margin-bottom: -5vh;
            left: -200px;
            justify-content: center;
    }

    .block-bg-container {
        display: none;
    }

    .buttons {
        width: 100%;
        flex-direction: column;
    }

    .cover-content {
        position: absolute;
        top:100vw;
        width: 90%;
        padding: 20px;
        background-color:rgba(255, 255, 255, 0.63);
        padding: 10px;
        border-radius: 25px;
    
    }
    .home-solver {
        margin-top: 150px;
    }
  } */
