.howitworks-top {
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 80%;
    padding-bottom: 20px;
}


.howitworks-top img, .howitworks-top div {
    margin:auto;
    max-width: 80%;
}

.howitworks-top .about-header {
    margin:auto;
    width: 80%;
    background-color: #FDBD48;
}

.howitworks-top .about-header p {
    font-size: 20px;
}

.howitworks-mid {
    margin: auto;
    /* padding: 5vw; */
    background-color: #ace6f2;
    
}

.howitworks-mid h2 {
    margin: auto;
    padding-top: 20px;
    width: 40%;
    font-weight: 600;
    font-size: 30px;
    line-height: 45px;
}

.howitworks-mid .sub-p {
    font-size: 25px;

}

.howitworks-mid .works-card {
    margin: auto;
    display: flex;
    width: 90%;
    margin-bottom: 20px;
}

.works-card {
    background-color: #f2ede9;
    border-radius: 20px;
    display: flex;
    align-items: center;
}

.works-card h5 {
    font-size: 18px;
    text-align: left;
    margin: 30px
}

.works-card h3 {
    font-size: 25px;
    text-align: left;
    margin: 30px

}

.works-card p {
    font-size: 25px;
    text-align: left;
    margin: 30px
}

.works-card div {
    flex: 1;
}

.works-card-left {
    border-right: 3px solid #ace6f2;
}


.works-card img {
    text-align: center;
    width: 40vh;
    /* margin: 30px */
}

.card2bg {
    background-color: #FFFFFF;
}

.char1-container {
    background-color: #ace6f2;
    margin-bottom: -100px;;
}
.char1-container img {
    width: 200px;
}

.motto-works {
    margin: auto;
    font-size: 30px;
    width: 60%;
    margin-top: 50px;
    margin-bottom: 50px;
}

.motto-p {
    font-size: 20px;
    margin-bottom: 50px;

}


@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .fortherapists-main *{
      margin: auto;
      display: flex;
      flex-direction: column;
      padding-bottom:10px;
      padding-top: 10px;
      width: 100%;
    }

    .howitworks-top {
        margin: auto;
        display: flex;
        flex-direction: column;
    }

    .times-container {
        padding: 0;
    }
    .process-container {
        /* border: 1px solid red;
        width: 200px */
        padding: 0;
    }

    .howitworks-top img, .howitworks-top div {
        margin:auto;
        max-width: 100%;
    }

    .howitworks-mid h2 {
        margin: auto;
        padding-top: 20px;
        width: 100%;
        font-weight: 600;
        font-size: 30px;
        line-height: 45px;
    }
    
    .howitworks-mid .works-card {
        margin: auto;
        display: flex;
        flex-direction: column;
        width: 90%;
        margin-bottom: 20px;
    }
    .works-card-left {
        border-right: 0;
        border-bottom: 3px solid #ace6f2;
    }

    .works-card {
        padding:5;
    }

    .works-card p {
        font-size: 25px;
        margin: 0;
    }
    
    .works-card h3 {
        font-size: 25px;
        text-align: left;
        margin: 0px;
        padding-bottom: 15px;
    
    }

    
  }