.contact-container {
    width: 100%;
    margin: auto;
    background-color: #ACE7F2;
    ;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* max-height: 2000px; */
    border-bottom-left-radius: 15vw;
    border-bottom-right-radius: 15vw;

}

.contact-container .headline {
    /* width: 60%; */
    margin: auto;
}

.contact-container .subcontainer {
    position: relative;
    padding: 0;
}

.contact-container .cloud-container {
    width: 100%;
    z-index: 2;
    display: flex;;
    margin: auto;
    display: flex;
    justify-content:space-between;
}

.motto {
    width: 30%;
    margin: auto;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    text-align: center;

    /* Black */
    color: #000000;
}

.form-container {
    display: flex;
    margin: auto;
    /* min-width: 60%; */
    max-width: 600px;
    /* padding: 2vw; */
    flex-direction: column;
    align-items: center;
}

.form-container input {
    padding: 2vh;
    width: 93%;
    max-width: 600px;
    border-radius: 14px;
    font-size: 20px;
    margin-bottom: 10px;
}

.form-container select {
    margin: auto;
    width: 100%;
    font-size: 20px;
    padding: 2vh;
    border-radius: 13px;
    background-color: white;
}

.form-container textarea {
    margin: auto;
    margin-top: 2vh;
    padding: 2vh;
    /* margin: 2vh; */
    border-radius: 13px;
    width: 93%;
    height: 20vh;
    font-size: 20px;
}

.form-container .submit-button {
    position: relative;
    width: 100%;
    bottom: 7vh;
    left: 31%;
}

.lounge-container {
    display: flex;
    justify-content: space-between;
    /* background-color: #ACE7F2; */
}

.lounge-container {
    /* margin-top: 2vh; */
    display: flex;
}


.lounge-container .img1 {
    /* width: 380px; */
    min-width: 350px;
    max-width: 380px;
    max-height: 380px;
    /* height: 380px; */
    z-index: 1;
    border-bottom-right-radius:120px;
}

.lounge-container .midwall {
    width: 100%;
    height: 70px;
    position: relative;
    top: 211px;
}

.lounge-container .img2 {
    /* flex: 0; */
    min-width: 350px;

    height: 380px;
    position: relative;
    bottom: 47px;
    z-index: 2;

}


.hypelist {
    /* width: 30%; */
    margin: auto;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 45px;
    /* padding: 3ch; */
    /* or 188% */
    text-align: center;

    /* Black */
    color: #000000;
}


.hypelist .waitlist-button {
    width: 70%;
}

.hype-container {
    display:flex;
    flex-direction: column;
    z-index: 3;

    position: relative;
        top: 20vh;
}

.bluefill {
    width: 100%;
    height: 100px;
    background-color: #ACE7F2;
    position: relative;
        top: -100px;
        margin-bottom: -100px;
    z-index: 0;
}



@media only screen and (max-width: 768px) {
    .motto {
        width: 80%;
    }

    .form-container {
        padding: 0;
    }

    .lounge-container .img1 {
        /* flex: 0; */
        width: 380px;
        height: 380px;
        z-index: 1;
        border-bottom-right-radius:120px;
    }
    
    .lounge-container .midwall {
        flex: 0;
        width: 100%;
        height: 70px;
        position: relative;
        top: 211px;
    }
    
    .lounge-container .img2 {
        /* flex: 0; */
        height: 380px;
        position: relative;
        bottom: 47px;
        z-index: 2;
    
    }


    .form-container .submit-button {
        position: relative;
        width: 100%;
        bottom: 7vh;
        left: 24%;
    }
        
}