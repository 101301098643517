
.fortherapists-top {
    margin: auto;
    display: flex;
    justify-content: center;
    width: 80%;
    flex-direction: row;
    align-items: center;
    padding-bottom: 10vh;;
}

.fortherapists-top img, .fortherapists-top div {
    margin:auto;
    max-width: 80%;
}

.fortherapists-top img {
    /* border: 2px solid blue; */
}

.fortherapists-top .about-header {
    margin:auto;
    width: 50%;
}

.fortherapists-top .about-header p {
    font-size: 20px;
}

.fortherapists-mid {
    margin: auto;
    /* padding: 5vw; */
    background-color: #f2ede9;
    padding-top: 20px;
    padding-bottom: 30px;
}

.fortherapists-mid h2 {
    margin: auto;
    width: 40%;
    font-weight: 600;
    font-size: 30px;
    line-height: 45px;
}

.fortherapists-mid .sub-p {
    font-size: 25px;
    /* margin: 50px; */
    /* margin-bottom: 100px; */
}

.fortherapists-mid .works-card {
    margin: auto;
    display: flex;
    width: 90%;
    /* margin-bottom: 20px; */
}

.fortherapists-card-container {
    display:flex;
    justify-content: center;
}

.fortherapists-card-container .works-card {
    text-align: center;
    background-color: #ffffff;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    max-width: 500px;
    height: 350px;
    z-index:1;
}

.fortherapists-card-container .works-card h5 {
    font-size: 18px;
    text-align: left;
    margin: 30px

}

.fortherapists-card-container .works-card h3 {
    font-size: 25px;
    text-align: left;
    margin: 30px

}

.fortherapists-card-container .works-card p {
    font-size: 25px;
    text-align: center;
    margin: 30px
}

.fortherapists-card-container .works-card div {
    flex: 1;
    padding: 2vw;
}

.fortherapists-card-container .works-card-left {
    border-right: 3px solid #ace6f2;
}


.fortherapists-card-container .works-card img {
    text-align: center;
    width: 100px;
    margin: 30px
}

.fortherapists-mid-low-container {
    background-color: #ace6f2;
    padding-top: 20px;
    padding-bottom: 20px;
}

.fortherapists-mid-low-container h2 {
    font-weight: 500;
    font-size: 36px;
    line-height: 55px;

    /* or 153% */
    text-align: center;
}

.fortherapists-mid-low-container p {
    margin:auto;
    width: 60%;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    text-align: center;
}

.tailor-blocks {
    background-color: #ace6f2;
    padding-bottom: 50px;
}

.row-tailor-container {
    display: flex;
    justify-content: center;
}

.row-tailor-container .tailor-block {
    margin: 30px;
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.row-tailor-container .tailor-block p {
    font-weight: 400;
    font-size: 16px;
    /* line-height: 150%; */
}

.row-tailor-container .tailor-block img {
    max-width: 50px;
}

.black-fortherapists-break-container {
    background-color: black;
    color:white;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    /* height: 400px; */
}

.black-fortherapists-break-container h3 {
    font-weight: 600;
    font-size: 36px;
    text-align: center;
    margin:0;
}

.black-fortherapists-break-container p {
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    /* or 30px */
    text-align: center;
}

.time-container {
    margin:auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* border: 1px solid blue; */
}

.time-container .process-container{
    width: 70%;
}

.fortherapists-howitworks-container {
    background-color: #ace6f2;
    position: relative;
    padding-top: 20px;

}

.fortherapists-lifestyle-container {
    background-color: #ace6f2;
    padding-bottom: 10px;
    padding-top: 20px;
}


@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .fortherapists-main *{
      margin: auto;
      display: flex;
      /* flex-direction: column; */
      padding: 0;
      width: 100%;
    }

    .fortherapists-mid h2, .fortherapists-mid p{
        width: 100%;
    }

    .fortherapists-mid .sub-p {
        margin: 0;
        display: flex; 
    }

    .fortherapists-mid-low-container p{
        width: 80%;
        margin: auto;
    }

    .row-tailor-container {
        /* border: 1px solid blue; */
        width: 80%;
        padding: 0;
        display: flex;
        /* flex-direction: column; */
        align-items: center;
    }
    .time-container .process-container{
        width: 100%;
    }

    .fortherapists-card-container .works-card p {
        overflow: hidden;
    }

    .fortherapists-lifestyle-container {
        background-color: #ace6f2;
        padding: 0;
        /* position: relative;
            top: -75px; */
    }

    .ftfooter-container {
        position:relative;
            top: -10px;
            margin-bottom: -10px;
    }
}