.home-about-container {
    width: 80%;
    border-radius: 100px;
    /* position: relative;
        top: -300px; */
    /* White */
    background: #FFFFFF;
    border-radius: 100px;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    /* border: 5px solid green; */
}

.home-about-container .headline {
    width: 70%;
    margin: auto;
}

.home-about-container .headline p {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    text-align: center;
    color: #000000;
}

.features {
    /* padding: 4vw; */
    display:flex;
    flex-direction: row;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.features .feature-card-container .desc {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-cont img {
    max-width: 250px;
}

.line {
    border: 2px solid #F2EDE9;
}

/* @media only screen and (max-width: 1000px) {
    .home-about-container {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        z-index: 2;
        position: relative;
            top: 1000px;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;
    }
    
    .feature-card-container {
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
  } */