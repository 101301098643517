.logo {
  margin: auto;
  width: 172px;
  height: 128px;
  background-image: url(../assets/cloudVector.png);
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo .p1 {
  font-family: "Pulp Display";
  font-weight: 300;
  font-size: 36.9317px;
  line-height: 140%;

  /* or 52px */
  text-align: center;
  letter-spacing: 0.05em;
  transform: translate(0px, -7px);
}

.logo .p2 {
  font-style: normal;
  font-weight: 800;
  font-size: 12.3422px;
  line-height: 140%;

  /* or 17px */
  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  transform: translate(0px, -7px);
}
