.solveblock-body {
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 100%;
}

.solveblock-container {
    margin: auto;
    width: 90%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #f2ede9;
    border-bottom-left-radius: 25%;
}

.button-container {
    margin: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.solveblock-container h4 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;

    /* or 30px */
    text-align: center;
}

.solveblock-container p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;

    /* or 24px */
    text-align: center;
}

.solveblock-container .solveblock-img {
    width: 90%;
}

.solveblock-card1 {
}

.solveblock-card1 .top-text {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

.solveblock-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.privstep {
    height: 400px;
}

.privstep-mobile {
    display: none;
}

.mark-trans-arrow-container {
    margin: auto;
    ;
    width: 90%;
    display: flex;
    justify-content: space-around;
    padding-bottom: 100px;
    ;
}

.mark-trans-arrow-container img {
    width: 300px;
}

.curve-black {
    background: #000000;
    border: 2px solid #000000;
    border-top-right-radius: 300px;

    /* transform: matrix(-0.98, -0.19, -0.19, 0.98, 0, 0); */
}

.curve-black h5 {
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 45px;
    /* or 173% */
    text-align: center;

    /* Grounding */
    color: #F2EDE9;
}


.curve-black h1 {
    margin: auto;
    width: 60%;
    font-style: normal;
    font-weight: 600;
    font-size: 52.6154px;
    line-height: 80px;
    margin-top: 10vh;
    padding-bottom: 10vh;
    /* or 153% */
    text-align: center;

    /* Grounding */
    color: #F2EDE9;
}

.curve-black button {
    /* Blue NEW */
    height: 49.5082893371582px;
    width: 211.33250427246094px;

    /* Black */
    background: #ACE7F2;
    border-radius: 117.877px;
    font-style: normal;
    font-weight: 400;
    font-size: 18.807px;
    line-height: 150%;

    /* or 28px */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    /* White */
    margin: auto;
    margin-bottom: 5vh;
}


@media only screen and (max-width: 768px) {

    /* For mobile phones: */
    .solveblock-container {
        /* scale: 0.9; */
        border-bottom-left-radius: 0%;
        display: flex;
        flex-direction: column;
    }

    .solveblock-container .top-text h3{

        margin: 0;
    }

    .solveblock-container h4 {
        width: 100%;
        margin: auto;
        font-size: 12px;
    }

    .solveblock-container p {
        width: 95%;
        margin: auto;
        font-size: 12px;
    }

    .solveblock-card {
        display: flex;
        flex-direction: column;
    }
    
    .solveblock-card1 .top-text {
        display:flex;
        flex-direction: column;
    }

    .mark-trans-arrow-container {
        margin: auto;
        ;
        width: 80%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding-bottom: 100px;
    }

    .mark-trans-arrow-container h1 {
        font-size: 13px;
    }
    
    .mark-trans-arrow-container img {
        width: 100%;
    }

    .privstep {
        display: none;
    }

    .privstep-mobile {
        display:block;
        margin: auto;
        width: 100%;
    }

}