nav {
  /* border:1px solid red; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: -10vh;
  position: relative;
  z-index: 1;
}

nav img {
  position: relative;
  /* bottom:-25px; */
  padding: 0;
  /* width:10%; */
}

.link-item {
  width: 100%;
  text-decoration: none;
  font-weight: 400;
  padding: 20px;
  color: black;
}

.link-item:focus {
  font-weight: 900;
}

.link-item p {
  margin: 0;
  transition: 0.2s;
}
.link-item:hover p {
  color: rgb(128, 128, 128);
}

/* @media screen  and (max-width: 600px) {
    nav {
        display:none;
    }
    
} */
