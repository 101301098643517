.homefooter-container {
    position: relative;
    max-height: 850px;
    overflow: hidden;
    margin:auto;
    background-color: #ACE7F2;
}

.homefooter-container p {
    /* width: 60%; */
    /* margin: auto; */
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 45px;
    text-align: center;
}

.homefooter-container .signup-headline {
    position: relative;
    margin: auto;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    text-align: center;
}

.hilltop {
    margin: auto;
    /* left:-% */
}