.contactfooter-container {
    position: relative;
    max-height: 800px;
    overflow: hidden;
    background-color: #ACE7F2;
}

.contactfooter-container h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 55px;
    /* or 153% */
}

.contactfooter-container p {
    width: 60%;
    margin: auto;
    /* Copy & Tags/Body - 26 */
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 45px;
    /* or 173% */
    text-align: center;
}



.hilltop {
    /* padding: 10vh; */
    padding-top: 20px;
    min-height: 100vw;
    max-height: 100vh;
    width:150%;
    position:relative;
        right:25%;
    background-color: #B1CC4F;
    border-radius: 50%;
}

