.jw-container {
    /* width: 50%; */
    /* min-width: 550px; */
    max-width: 100%;;
    margin: auto;
    margin-top: 20px;
}

.jw-container input, button {
    border-radius: 25px;
    font-size: 20px;
    padding: 10px;
    margin:auto;
    text-align: center;

}

.jw-container input {
    width: 300px;
    margin-bottom: 20px;
    /* min-width: 400px; */
    /* margin-right: -50px; */
}

.jw-container button {
    width: 150px;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    text-align: center;
    color: white;
    font-weight: 400;
    cursor: pointer;

}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .jw-container {
      display:flex;
      max-width: 90%;;
      flex-direction: column;
      justify-content: center;
    }

    .jw-container button {
        text-align: center;
    }

    .jw-container input {
        width: 250px;
        margin-bottom: 20px;

    }
}