.forclients-top {
    margin: auto;
    display: flex;
    justify-content: center;
    width: 80%;
    flex-direction: row;
    align-items: center;
    padding-bottom: 10vh;
}

.forclients-top img, .forclients-top div {
    margin:auto;
    max-width: 280px;
}

.forclients-top .about-header {
    margin:auto;
    width: 50%;
    background-color: #ace6f2;
}

.forclients-top .about-header p {
    padding-top: 30px;
    font-size: 20px;
}

.forclients-midforclients-mid {
    margin: auto;
    /* padding: 5vw; */
    background-color: #f2ede9;
}

.forclients-mid h2 {
    padding-top: 20px;;
}

.black-waitlist-clients-container {
    background-color: black;
    color: white;
    display:flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 40px;
    text-align: left;
    margin-bottom: -20px;;

}

.black-waitlist-clients-container h3 {
    font-weight: 600;
    font-size: 36px;
    line-height: 55px;
}

.black-waitlist-clients-container p {
    margin-top: -20px;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
}

.diversebg-container img {
    width: 100%;
}

.forclients-inhands-container {
    background-color: #b1cc4f;
    padding: 30px;
}

.forclients-inhands-container h2 {
    font-weight: 500;
    font-size: 36px;
    line-height: 55px;

    /* or 153% */
    text-align: center;
}

.forclients-inhands-container h5 {
    margin:auto;
    width: 50%;
    font-weight: 450;
    font-size: 22px;
}

.forclients-inhands-container p{
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    /* padding: 10px; */
    line-height: 150%;
    font-weight: 400;
    font-size: 18px;
    width: 50%;
}

.forclients-mid h2 {
    margin: auto;
    /* width: 40%; */
    font-weight: 600;
    font-size: 30px;
    line-height: 45px;
}

.forclients-mid .sub-p {
    font-size: 25px;
    /* margin: 50px; */
    padding-bottom: 20px;;
}

.forclients-mid .clients-card-container {
    /* margin-top: -50px; */
    display:flex;
}

.clients-card-container .works-card h3 {
    width: 100%;
    text-align: center;
}

.forclients-mid .works-card {
    background-color: white;
    margin: 30px;
    display: flex;
    height: 400px;
    width:80%;
    margin-bottom: 20px;
    display:flex;
    flex-direction: column;
    justify-content: center;
}
.forclients-mid .works-card .img-cont{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.forclients-mid .works-card .img-cont img{
    width: 50%;
}
.forclients-mid .works-card .img-cont #two{
    width: 40%;
}

.forclients-mid-low-container h5 {
    padding-bottom: 30px;
}

.fc-bigrun-container {
    display: flex;
    flex-direction: column;
    margin:auto;
    align-items: center;

}

.fc-finrun-container h2{
    font-weight: 600;
    font-size: 36px;
    line-height: 55px;
}

.fc-finrun-container p {
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
}

.fc-fin-line {
    padding-top: 30px;
}

.process-container{
    flex: 1;
    padding: 10px;
    width:60%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.process-container div{
    flex:1;
}

.process-container img {
    padding-top: 20px;
    padding-bottom: 20px;
    max-width: 200px;
}



@media only screen and (max-width: 768px) {

    /* For mobile phones: */
    .forclients-top{
      padding-top: 10px;
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .forclients-mid * {
        padding: 0;
        margin: auto;
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .about-header {
        min-width: 90%;
    }

    .black-waitlist-clients-container {
        background-color: black;
        color: white;
        display:flex;
        padding: 0;
        margin: auto;
        text-align: center;
        flex-direction: column;
        justify-content: space-between;
        align-items: baseline;
    }

    .forclients-inhands-container {
        width: 100%;
        padding: 0;
    }
    .forclients-inhands-container p, .forclients-inhands-container h5{
        padding: 0;
        width: 90%;
    }

    .fc-bigrun-container {
        padding: 0;
        min-width: 100%;
        display: flex;
        flex-direction: column;
    }
    .process-container{
        flex: 1;
        /* padding: 10px; */
        max-width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
    }
    .process-container * {
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0;
        max-width: 90%;
    }

    .works-card {
        padding: 10px;
    }
  }