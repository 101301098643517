.feature-card-container {
    padding: 1vw;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.feature-card-container .desc {
    width:100%;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    height: 100px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: baseline;

    /* Black */
    color: #000000;
    position: relative;
        /* bottom:2vh; */
}

.feature-card-container .card-cont {
    flex:1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.feature-img {
    max-width: 95%;
}

.anonymity-client {
    width:170px;
}

.emote-globe{
    width: 400px;
    padding-top: 3vh;
    margin-bottom: 78px;;
}