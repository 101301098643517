.helptc-body {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    background: #FFFFFF;
}

.helptc-container {
    margin: auto;
    width: 95%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* border: 1px solid blue; */

}


.helptc-container h4 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    text-align: center;
}

.helptc-container p {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    /* or 30px */
    text-align: center;
}

.helptc-container .helptc-img {
    width: 300px;
    height: 300px;
    max-height: 300px
}

.helptc-card {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;

    justify-content: space-evenly;
}

.curve-black {
    background: #000000;
    border: 2px solid #000000;
    /* transform: matrix(-0.98, -0.19, -0.19, 0.98, 0, 0); */
}

.curve-black h5 {
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 45px;
    /* or 173% */
    text-align: center;
    /* Grounding */
    color: #F2EDE9;
}

.curve-black h1 {
    margin: auto;
    width: 60%;
    font-style: normal;
    font-weight: 600;
    font-size: 52.6154px;
    line-height: 80px;
    /* or 153% */
    text-align: center;
    /* Grounding */
    color: #F2EDE9;
}

.curve-black button {
    /* Blue NEW */
    height: 49.5082893371582px;
    width: 211.33250427246094px;
    /* Black */
    background: #ACE7F2;
    border-radius: 117.877px;
    font-style: normal;
    font-weight: 400;
    font-size: 18.807px;
    line-height: 150%;
    /* or 28px */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* White */
    margin: auto;
    /* margin-bottom: 5vh; */
}

.helptc-container .line {
    border: 2px solid #F2EDE9;
    /* height: 50vh; */
}

@media only screen and (max-width: 850px) {

    /* For mobile phones: */
    .helptc-container {
        flex-direction: column;
    }
}