.black-button {
    height: 49.5082893371582px;
    cursor: pointer;

    /* width: 10vw; */
    min-width:200px;
    max-width: 200px;


    /* Black */
    background: #000000;
    border-radius: 117.877px;    
    font-style: normal;
    font-weight: 400;
    font-size: 18.807px;
    line-height: 150%;

    /* or 28px */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    /* White */
    color: #FFFFFF;
    margin:auto;
}

.black-button:hover {
    background-color: rgb(88, 88, 88);
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .black-button {
        max-width: 200px;
    }

}